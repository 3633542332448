<template>
<b-nav-item :to="{name: 'activities-index-in-corso' }">
    <feather-icon
    size="21"
    icon="CalendarIcon"
    />
</b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'

export default {
components: {
    BNavItem,
},
setup() {

},
}
</script>