export default [
  {
    title: 'Dashboard',
    route: 'area-clienti-dashboard',
    icon: 'UserIcon',
    action: 'customer',
    resource: 'ACL',
  },
  {
    title: 'Profilo ',
    route: 'area-clienti-profilo',
    icon: 'UserIcon',
    action: 'customer',
    resource: 'ACL',
  },
  {
    title: 'Licenze ',
    route: 'area-clienti-licenze',
    icon: 'AwardIcon',
    action: 'customer',
    resource: 'ACL',
  },
  {
    title: 'Pagamenti ',
    route: 'area-clienti-pagamenti',
    icon: 'CheckCircleIcon',
    action: 'customer',
    resource: 'ACL',
  },
]
