export default [
  {
    header: 'Area Demo',
    action: 'employee',
    resource: 'manage',
  },
  {
    title: 'Utenti api GoodTable',
    route: 'crud-utenti',
    icon: 'UsersIcon',
    action: 'employee',
    resource: 'manage',
  },
  {
    title: 'GoodTable locale',
    route: 'crud-goodtable',
    icon: 'GridIcon',
    action: 'employee',
    resource: 'manage',
  },
  {
    title: 'Auto-Logout',
    route: 'crud-autologout',
    icon: 'LogOutIcon',
    action: 'employee',
    resource: 'manage',
  },
]
