/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: [
      /*
      { title: 'Utenti', route: { name: 'crm-utenti-index' }, icon: 'UserIcon', isBookmarked: true },
      { title: 'Leads', route: { name: 'crm-leads-index' }, icon: 'UsersIcon', isBookmarked: true },
      { title: 'Clienti', route: { name: 'crm-customers-index' }, icon: 'BriefcaseIcon', isBookmarked: true },
      */

      /*
      { title: 'Access Control', route: { name: 'access-control' }, icon: 'ShieldIcon', isBookmarked: false },
      { title: 'Account Settings', route: { name: 'pages-account-setting' }, icon: 'SettingsIcon', isBookmarked: false },
      { title: 'Advance Card', route: { name: 'card-advance' }, icon: 'CreditCardIcon', isBookmarked: false },
      { title: 'Todo', route: { name: 'apps-todo' }, icon: 'CheckSquareIcon', isBookmarked: true },
      */
    ],
  },
  files: {
    key: 'file_name',
    data: [
      {
        file_name: "Joe's CV",
        from: 'Stacy Watson',
        icon: require('@/assets/images/icons/doc.png'),
        size: '1.7 mb',
      },
    ],
  },
  contacts: {
    key: 'name',
    data: [
      {
        img: require('@/assets/images/portrait/small/avatar-s-4.jpg'),
        name: 'Rena Brant',
        email: 'nephrod@preany.co.uk',
        time: '21/05/2019',
      },
    ],
  },
}
/* eslint-enable */
