<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">

      <!-- Bookmarks Container -->
      <bookmarks />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto" v-if="localUserData.role == 'Cliente'">
      <dark-Toggler class="d-none d-lg-block" />

      <user-dropdown />
    </b-navbar-nav>

    <b-navbar-nav class="nav align-items-center ml-auto" v-else>
      <dark-Toggler class="d-none d-lg-block" />
      <!-- <search-bar /> -->

      <GoogleGmail />

      <Activities />

      <notification-dropdown class="d-none d-lg-block" />
      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav,
} from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import SearchBar from './components/SearchBar.vue'
import DarkToggler from './components/DarkToggler.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import UserDropdown from './components/UserDropdown.vue'
import GoogleGmail from './components/GoogleGmail.vue'
import Activities from './components/Activities.vue'
import { mapState } from 'vuex';

export default {
  components: {
    BLink,

    // Navbar Components
    BNavbarNav,
    Bookmarks,
    SearchBar,
    DarkToggler,
    NotificationDropdown,
    UserDropdown,
    GoogleGmail,
    Activities,

    mapState,
  },
  data() {
    return {
      localUserData: null, // Variabile locale per `userData`

    }
  },
  computed: {
    ...mapState({
      userData: 'userDataStore' // mappa 'userDataStore' dallo store per passare i dati verso 'userData' in questo componente
    })
  },
  created() {
    const storedUserData = localStorage.getItem('userData');

    if (storedUserData) {
      this.localUserData = JSON.parse(storedUserData);
    }
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
