<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    ref="dropdown"
  >
    <template #button-content>
      <feather-icon
        :badge=$store.state.app.notifiche.total
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <div v-if="$store.state.app.notifiche.total > 0">
      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Notifiche
          </h4>
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <b-link
          v-for="notification in $store.state.app.notifiche.message"
          :key="notification.subtitle"
          @click="vaiShow(notification.id)"
        >
          <b-media>
            <template #aside>
              <b-avatar
                size="32"
                :src="notification.avatar"
                :text="notification.avatar"
                :variant="notification.type"
              />
            </template>
            <p class="media-heading">
              <span v-if="notification.colortext == 'warning'" class="font-weight-bolder text-warning">{{ notification.title }}</span>
              <span v-else-if="notification.colortext == 'danger'" class="font-weight-bolder text-danger">{{ notification.title }}</span>
              <span v-else class="font-weight-bolder">{{ notification.title }}</span>
            </p>
            <small class="notification-text">{{ notification.subtitle }}</small>
          </b-media>
        </b-link>

      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer"><b-button 
        :to="{name: 'attivita-index' }"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
      >Tutte le notifiche</b-button>
      </li>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  methods: {
    vaiShow(idTask){
      this.$refs.dropdown.hide(true)

      this.$router.replace('/attivita/show/'+idTask)
    }
  }
}
</script>

<style>

</style>
