<template>
  <b-nav-item :to="{name: 'google-workspace-gmail-index' }">
    <feather-icon
      size="21"
      icon="MailIcon"
    />
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'

export default {
  components: {
    BNavItem,
  },
  setup() {

  },
}
</script>
