<template>
  <div>
    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
      v-if="localUserData.role == 'Cliente'"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.name+' '+userData.surname }}
          </p>
          <span class="user-status">{{ userData.nome_azienda }}</span>
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
        >
          <feather-icon
            v-if="!userData.fullName"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>

      <b-dropdown-item
        :to="{ name: 'area-clienti-profilo' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Utente</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'area-clienti-profilo-cambio-password' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UnlockIcon"
          class="mr-50"
        />
        <span>Cambio Password</span>
      </b-dropdown-item>

      <b-dropdown-divider />

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
      v-else
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.name+' '+userData.surname }}
          </p>
          <span class="user-status">{{ userData.role }}</span>
        </div>
        <b-avatar
          size="40"
          :src="userData.avatar"
          variant="light-primary"
          badge
          class="badge-minimal"
          :badge-variant="userData.availability_status.color"
        >
          <feather-icon
            v-if="!userData.fullName"
            icon="UserIcon"
            size="22"
          />
        </b-avatar>
      </template>

      <!--
      <b-dropdown-item
        :to="{ name: 'pages-profile'}"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UserIcon"
          class="mr-50"
        />
        <span>Profile</span>
      </b-dropdown-item>

      <b-dropdown-item
        :to="{ name: 'apps-email' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="MailIcon"
          class="mr-50"
        />
        <span>Inbox</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'apps-todo' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CheckSquareIcon"
          class="mr-50"
        />
        <span>Task</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'apps-chat' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="MessageSquareIcon"
          class="mr-50"
        />
        <span>Chat</span>
      </b-dropdown-item>

      <b-dropdown-divider />

      <b-dropdown-item
        :to="{ name: 'pages-account-setting' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Settings</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-pricing' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="CreditCardIcon"
          class="mr-50"
        />
        <span>Pricing</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'pages-faq' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="HelpCircleIcon"
          class="mr-50"
        />
        <span>FAQ</span>
      </b-dropdown-item>
      -->

      <b-dropdown-item
        :to="{ name: 'profilo-index' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="SettingsIcon"
          class="mr-50"
        />
        <span>Utente</span>
      </b-dropdown-item>
      <b-dropdown-item
        :to="{ name: 'profilo-change-password' }"
        link-class="d-flex align-items-center"
      >
        <feather-icon
          size="16"
          icon="UnlockIcon"
          class="mr-50"
        />
        <span>Cambio Password</span>
      </b-dropdown-item>

      <b-dropdown-divider />
      
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="handleClick(userData.id, userData.availability_id)"
      >
        <feather-icon
          size="16"
          icon="CheckCircleIcon"
          class="mr-50"
        />
        <span>Imposta presenza</span>
      </b-dropdown-item>

      <b-dropdown-divider />

      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Logout</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-modal
      ref="availabiliy-modal"
      hide-footer
      title="Imposta presenza"
      modal-class="modal-primary"
      >
      <b-card-text>
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-select
              v-model="campiform.availability_id"
              :options="options"
              />

            </b-col>
            <b-col md="6">
              <b-button
                variant="primary"
                type="submit"
                :disabled="action_press"
                @click.prevent="aggiornaAvailability()"
                >
                <div v-if="action_press"><b-spinner small class="mx-1" /></div>
                <div v-else>Salva</div> 
                </b-button>

            </b-col>
          </b-row>

        </b-form>
      </b-card-text>
    </b-modal>

  </div>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, BCard, BCardText, BRow, BCol, BSpinner, BForm, BFormSelect, BButton
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import { mapState } from 'vuex';

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BCard, 
    BCardText, 
    BRow, 
    BCol, 
    BSpinner, 
    BForm, 
    BFormSelect, 
    BButton,
    mapState,
  },
  data() {
    return {
      localUserData: null, // Variabile locale per `userData`
      avatarText,

      campiform: {
        id: '',
        availability_id: '',
      },
      options: [
        { value: 1, text: 'Disponibile' }, 
        { value: 2, text: 'Occupato' },
        { value: 3, text: 'Non disponibile' },
      ],
      
      action_press: false,
    }
  },
  computed: {
    ...mapState({
      userData: 'userDataStore' // mappa 'userDataStore' dallo store per passare i dati verso 'userData' in questo componente
    })
  },
  created() {
    const storedUserData = localStorage.getItem('userData');

    if (storedUserData) {
      this.localUserData = JSON.parse(storedUserData);
      // Aggiorna `userDataStore` nel Vuex store usando un'azione Vuex
      this.$store.commit('setUserData', this.localUserData);
    }
  },
  methods: {
    logout() {
      
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
    handleClick(id_user, availability_id) {
      this.campiform.id = id_user;
      this.campiform.availability_id = availability_id;

      this.$refs['availabiliy-modal'].show()

    },
    aggiornaAvailability() {
      console.log("qui -------------")
      console.log(this.campiform)

      //this.action_press = true;

      this.$http.post('api/crm/user/change_user_availability', 
        this.campiform
      ).then(response => { 
        //console.log("risposta server --------")
        //console.log(response.data)

        //aggiorno localStorage
        let userData = JSON.parse(localStorage.getItem('userData'));
        userData.availability_id = response.data.reply.dati.availability_id;
        userData.availability_status = response.data.reply.dati.availability_status;
        localStorage.setItem('userData', JSON.stringify(userData));

        //aggiorno dati su questo componente (il cerchietto della disponibilità dell'utente)
        this.userData.availability_id = response.data.reply.dati.availability_id;
        this.userData.availability_status.color = response.data.reply.dati.availability_status.color;


        //chiudi finestra
        this.$refs['availabiliy-modal'].hide();

        this.$swal({
          icon: 'success',
          title: response.data.reply.reply,
          //text: 'operazione effettuata correttamente',
          confirmButtonText: 'chiudi',
          customClass: {
          confirmButton: 'btn btn-success',
          },
        })

      }).catch(e => {
        console.log(e);
      });

    }
  },
}
</script>